import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step context current`}</strong>{` -- current returns the name of the current context`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step context current [--json]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step context current`}</strong>{` returns the name of the current context.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--json`}</strong>{`
Return stringified JSON containing the main attributes of a context.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`List all certificate authority contexts:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step context current
test-ca
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step context current --json
{"name":"test-ca","authority":"internal.ca.smallstep.com","profile":"test-ca"}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      